/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

/**
 * Dada una instancia de un componente de vue, realiza las acciones necesarias para el 'deslogueo'
 * Remueve el token del localStorage, y los datos del usuario del storage de vuex.
 * Redirige a la pantalla de login.
 * @param {Vue} vueInstance la instancia del componente de vue desde la cual se deslogue el usuario
 */
export function logout(vueInstance) {
  vueInstance.$store.dispatch('clearStateAuth')
  vueInstance.$store.dispatch('setItemsMenu', [])
  window.localStorage.removeItem('ls.X-AUTHENTICATION-TOKEN')
  window.localStorage.removeItem('ls.CURRENT-USER')
  window.localStorage.removeItem('ls.USER-NAME')
  window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS')
  window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-INS')
  window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-UPD')
  window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-DEL')
  window.localStorage.removeItem('ls.MODULOS-CON-PERMISOS-VER')
  vueInstance.$router.push('/login')
}

/**
 * Realiza las acciones necesarias para el logue de un usuario luego que fue autenticado correctamente.
 * Setea el token el el localStorage y los datos del usuario en el store de vuex.
 * Redirige a la pantalla indicada, por defecto '/events'
 * @param {Vue} vueInstance la instancia del componente de vue desde la cual se logueo
 * @param {Object} resultsLoginUser el dto con los datos del usuario logueado
 * @param {String} whereTo el componente de vue al cual navegar
 */
export async function doLogin(vueInstance, resultsLoginUser, whereTo) {
  vueInstance.$store.dispatch('guardarPermisosLocalStorage', { usuario: resultsLoginUser.user, token: resultsLoginUser.token })
  vueInstance.$store.dispatch('guardarPermisosVuex', { usuario: resultsLoginUser.user, token: resultsLoginUser.token })
  if (whereTo) {
    vueInstance.$router.push(whereTo)
  }
}

/**
 * Devuelve al JWT del usuario que se logueó a través de google.
 * @param {Object} currentUser el usuario logueado desde google
 */
export function getIdTokenFromCurrentUser(currentUser) {
  //  Cuando el login se hace a través de google, vue guarda una 'copia' del usuario 'currentUser'.
  //  Este usuario contiene el JWT en una subpropiedad, pero como las propiedades cambian con el tiempo,
  //  se tiene que buscar individualmente entre las subpropiedades
  Object.keys(currentUser).forEach(key => {
    const propiedad = currentUser[key]
    if (typeof propiedad === 'object') {
      Object.keys(propiedad).forEach(subKey => {
        const subPropiedad = propiedad[subKey]
        if (typeof subPropiedad === 'object' && subPropiedad.id_token) {
          return subPropiedad.id_token
        }
      })
    }
  })
  return null
}
