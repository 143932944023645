import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'
// Modules
import auth from 'trx-vue-common/src/security/auth'
import modules from './modulos'

const ls = new SecureLS({ isCompression: false })
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  mutations: {},
  getters: {},
  modules: {
    ...modules,
    auth,
  },
  // strict: process.env.DEV,
})
/* export default new Vuex.Store({
  modules: {
    ...modules,
    auth,
  },

}) */
