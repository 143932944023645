/* eslint-disable no-shadow */
import {
  itemMenuResource,
} from '@/services/itemMenuResource'

const itemMenuInicio = {
  title: 'Inicio',
  route: '/inicio',
  icon: 'fas fa-home',
}

const itemMenuError = {
  title: 'Error',
  cssClass: 'text-danger',
  icon: 'fas fa-frown',
  tooltip: {
    title: 'Hubo un error al intentar obtener los Menús',
    variant: 'danger',
  },
}

const itemMenuLoading = {
  title: 'Cargando',
  cssClass: 'text-info',
  icon: 'fas fa-spinner',
  tooltip: {
    title: 'Cargando los Menús. Por favor, aguarde',
    variant: 'primary',
  },
}

const state = {
  itemsMenu: [],
  loading: false,
  error: false,
}

const mutations = {
  SET_ITEMS_MENU(state, itemsMenu) {
    state.itemsMenu = itemsMenu
  },
  SET_ITEMS_MENU_LOADING(state, flag) {
    state.loading = flag
  },
  SET_ITEMS_MENU_ERROR(state, flag) {
    state.error = flag
  },
}

const actions = {
  setItemsMenu: ({ commit }, itemsMenu) => {
    commit('SET_ITEMS_MENU', itemsMenu)
  },
  setItemsMenuLoading: ({ commit }, flag) => {
    commit('SET_ITEMS_MENU_LOADING', flag)
  },
  setItemsMenuError: ({ commit }, flag) => {
    commit('SET_ITEMS_MENU_ERROR', flag)
  },
  fetchItemsMenu: ({ dispatch }) => new Promise((resolve, reject) => {
    if (!state.loading) {
      dispatch('setItemsMenuLoading', true)
      itemMenuResource().getByUsuario()
        .then(data => {
          dispatch('setItemsMenuError', false)
          dispatch('setItemsMenu', data)
          resolve(data)
        })
        .catch(error => {
          console.error(error)
          dispatch('setItemsMenuError', true)
          reject(error)
        })
        .finally(() => {
          dispatch('setItemsMenuLoading', false)
        })
    }
  }),
}

const getters = {
  getItemsMenuLoading(state) {
    return state.loading
  },
  getItemsMenuError(state) {
    return state.error
  },
  getItemsMenu(state) {
    let baseItems = [itemMenuInicio]
    if (state.error) {
      baseItems = baseItems.concat(itemMenuError)
    }
    if (state.loading) {
      baseItems = baseItems.concat(itemMenuLoading)
    }
    return baseItems.concat(state.itemsMenu)
  },
}

export default {
  state, mutations, actions, getters,
}
