/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  postlogin,
  postEmailResetPassword,
  postRegister,
  postRenewToken,
} from '@/utils/paths'

import axios from 'axios'

const pathLogin = postlogin
const pathPostEmailResetPassword = postEmailResetPassword
const pathPostRegister = postRegister
const pathPostRenewToken = postRenewToken

export function authenticationResource() {
  function loginUser(authenticationRequest) {
    return new Promise((resolve, reject) => {
      axios.post(pathLogin, authenticationRequest)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function registerUser(registerRequest) {
    return new Promise((resolve, reject) => {
      axios.post(pathPostRegister, registerRequest)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function sendEmailResetPassword(emailRegisterDto) {
    return new Promise((resolve, reject) => {
      axios.post(pathPostEmailResetPassword, emailRegisterDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function renewToken(authenticationRequest) {
    return new Promise((resolve, reject) => {
      axios.post(pathPostRenewToken, authenticationRequest)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    loginUser,
    sendEmailResetPassword,
    registerUser,
    renewToken,
  }
}
