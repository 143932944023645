/* eslint-disable no-shadow */
import {
  usuarioEstadoResource,
} from '@/services/usuarioEstadoResource'

const state = {
  usuarioEstado: [],
}

const mutations = {
  SET_USUARIO_ESTADO(state, usuarioEstado) {
    state.usuarioEstado = usuarioEstado
  },
}

const actions = {
  setUsuarioEstado: ({ commit }, usuarioEstado) => {
    commit('SET_USUARIO_ESTADO', usuarioEstado)
  },
  fetchUsuarioEstado: ({ dispatch }) => new Promise((resolve, reject) => {
    usuarioEstadoResource().getAllUsuarioEstado()
      .then(data => {
        dispatch('setUsuarioEstado', data)
        resolve(data)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
      .finally(() => {
      })
  }),
}

const getters = {
  getUsuarioEstado(state) {
    return state.usuarioEstado
  },
}

export default {
  state, mutations, actions, getters,
}
