export default [
  {
    path: '/panelbeneficios',
    name: 'panelBeneficios',
    component: () => import('@/views/beneficios/solicitud/PanelBeneficios.vue'),
    meta: {
      nameParent: 'panelbeneficios',
      modulo: 'SOLICITUD_BENEFICIO',
      permiso: 'VER',
    },
  },
  {
    path: '/panelbeneficios/view/:beneficioId',
    name: 'solicitud-view',
    component: () => import('@/views/beneficios/solicitud/SolicitudGenerico.vue'),
    props: route => ({ beneficioId: route.params.beneficioId, openMode: 'view' }),
    meta: {
      modulo: 'SOLICITUD_BENEFICIO',
      nameParent: 'panelbeneficios',
    },
  },
]
