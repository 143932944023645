export default [
  {
    path: '/beneficiolista',
    name: 'beneficio',
    component: () => import('@/views/beneficios/configuracion/BeneficioLista.vue'),
    meta: {
      nameParent: 'beneficio',
      modulo: 'CONFIGURACION_BENEFICIO',
      permiso: 'VER',
    },
  },

  {
    path: '/beneficio/view/:idBeneficio',
    name: 'beneficio-view',
    component: () => import('@/views/beneficios/configuracion/ConfigBeneficio.vue'),
    props: route => ({ idBeneficio: route.params.idBeneficio, openMode: 'view' }),
    meta: {
      // pageTitle: 'Usuarios',
      nameParent: 'beneficio',
    },
  },
  {
    path: '/beneficio/edit/:idBeneficio',
    name: 'beneficio-edit',
    component: () => import('@/views/beneficios/configuracion/ConfigBeneficio.vue'),
    props: route => ({ idBeneficio: route.params.idBeneficio, openMode: 'edit' }),
    meta: {
      // pageTitle: 'Usuarios',
      nameParent: 'beneficio',
    },
  },
  {
    path: '/beneficio/new',
    name: 'beneficio-new',
    component: () => import('@/views/beneficios/configuracion/ConfigBeneficio.vue'),
    props: { openMode: 'new' },
    meta: {
      nameParent: 'beneficio',
    },
  },
]
