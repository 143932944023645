/* eslint-disable no-shadow */
import {
  beneficioTipoResource,
} from '@/services/beneficioTipoResource'

const state = {
  beneficioTipo: [],
}

const mutations = {
  SET_BENEFICIO_TIPO(state, beneficioTipo) {
    state.beneficioTipo = beneficioTipo
  },
}

const actions = {
  setBeneficioTipo: ({ commit }, beneficioTipo) => {
    commit('SET_BENEFICIO_TIPO', beneficioTipo)
  },
  fetchBeneficioTipo: ({ dispatch }) => new Promise((resolve, reject) => {
    beneficioTipoResource().findAllBeneficioTipo()
      .then(data => {
        dispatch('setBeneficioTipo', data)
        resolve(data)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
      .finally(() => {
      })
  }),
}

const getters = {
  getBeneficioTipo(state) {
    return state.beneficioTipo
  },
}

export default {
  state, mutations, actions, getters,
}
