/* eslint-disable no-shadow */
import {
  delegacionResource,
} from '@/services/delegacionResource'

const state = {
  delegacion: [],
}

const mutations = {
  SET_DELEGACION(state, delegacion) {
    state.delegacion = delegacion
  },
}

const actions = {
  setDelegacion: ({ commit }, delegacion) => {
    commit('SET_DELEGACION', delegacion)
  },
  fetchDelegacion: ({ dispatch }) => new Promise((resolve, reject) => {
    delegacionResource().findAllByIsActivoDelegacion()
      .then(data => {
        dispatch('setDelegacion', data)
        resolve(data)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
      .finally(() => {
      })
  }),
}

const getters = {
  getDelegacion(state) {
    return state.delegacion
  },
}

export default {
  state, mutations, actions, getters,
}
