export default [
  {
    path: '/stocklista',
    name: 'stock',
    component: () => import('@/views/stock/StockLista.vue'),
    meta: {
      nameParent: 'stock',
      modulo: 'ABM_STOCK',
      permiso: 'VER',
    },
  },
]
