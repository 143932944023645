/* eslint-disable no-shadow */
import {
  rolResource,
} from '@/services/rolResource'

const state = {
  roles: [],
}

const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles
  },
}

const actions = {
  setRoles: ({ commit }, roles) => {
    commit('SET_ROLES', roles)
  },
  fetchRoles: ({ dispatch }) => new Promise((resolve, reject) => {
    rolResource().getAllRoles()
      .then(data => {
        dispatch('setRoles', data)
        resolve(data)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
      .finally(() => {
      })
  }),
}

const getters = {
  getRoles(state) {
    return state.roles
  },
}

export default {
  state, mutations, actions, getters,
}
