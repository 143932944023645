export default [
  {
    path: '/empleadores',
    name: 'empleador',
    component: () => import('@/views/empleador/EmpleadorLista.vue'),
    meta: {
      nameParent: 'empleador',
      modulo: 'ABM_EMPLEADORES',
      permiso: 'VER',
    },
  },
  {
    path: '/empleador/view/:idEmpleador',
    name: 'empleador-view',
    component: () => import('@/views/empleador/DetalleEmpleador.vue'),
    props: route => ({ idEmpleador: route.params.idEmpleador, openMode: 'view' }),
    meta: {
      nameParent: 'empleadores',
    },
  },
  {
    path: '/empleador/edit/:idEmpleador',
    name: 'empleadores-edit',
    component: () => import('@/views/empleador/DetalleEmpleador.vue'),
    props: route => ({ idEmpleador: route.params.idEmpleador, openMode: 'edit' }),
    meta: {
      nameParent: 'empleadores',
    },
  },
  {
    path: '/empleador/incompleteLoad/:idEmpleador',
    name: 'empleadores-incomplete-load',
    component: () => import('@/views/empleador/Empleador.vue'),
    props: route => ({ idEmpleador: route.params.idEmpleador, openMode: 'incompleteLoad' }),
    meta: {
      nameParent: 'empleadores',
    },
  },
  {
    path: '/empleador/new',
    name: 'empleadores-new',
    component: () => import('@/views/empleador/Empleador.vue'),
    props: { openMode: 'new' },
    meta: {
      nameParent: 'empleadores',
    },
  },
]
