export default [
  {
    path: '/afiliados',
    name: 'afiliado',
    component: () => import('@/views/afiliado/AfiliadoLista.vue'),
    meta: {
      nameParent: 'afiliado',
      modulo: 'ABM_AFILIADOS',
      permiso: 'VER',
    },
  },
  {
    path: '/afiliado/view/:idAfiliado',
    name: 'afiliado-view',
    component: () => import('@/views/afiliado/DetalleAfiliado.vue'),
    props: route => ({ idAfiliado: route.params.idAfiliado, openMode: 'view' }),
    meta: {
      nameParent: 'afiliados',
    },
  },
  {
    path: '/afiliado/edit/:idAfiliado',
    name: 'afiliados-edit',
    component: () => import('@/views/afiliado/DetalleAfiliado.vue'),
    props: route => ({ idAfiliado: route.params.idAfiliado, openMode: 'edit' }),
    meta: {
      nameParent: 'afiliados',
    },
  },
  {
    path: '/afiliado/incompleteLoad/:idAfiliado',
    name: 'afiliados-incomplete-load',
    component: () => import('@/views/afiliado/Afiliado.vue'),
    props: route => ({ idAfiliado: route.params.idAfiliado, openMode: 'incompleteLoad' }),
    meta: {
      nameParent: 'afiliados',
    },
  },
  {
    path: '/afiliado/new',
    name: 'afiliados-new',
    component: () => import('@/views/afiliado/Afiliado.vue'),
    props: { openMode: 'new' },
    meta: {
      nameParent: 'afiliados',
    },
  },
]
