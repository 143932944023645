/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllUsuarioEstado,
} from '@/utils/paths'

const pathGetAllUsuarioEstado = getAllUsuarioEstado

export function usuarioEstadoResource() {
  function getAllUsuarioEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllUsuarioEstado,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllUsuarioEstado,
  }
}
