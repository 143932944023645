import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  data() {
    return {
      swalConfig: {
        background: 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)',
        textColor: 'white',
      },
    }
  },
  created() {
  },
  methods: {
    setSwalColor() {
      const { skin } = useAppConfig()
      if (skin.value === 'dark') {
        // this.swalConfig.background = 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)'
        this.swalConfig.background = '#283046'
        this.swalConfig.textColor = 'white'
      } else {
        // this.swalConfig.background = 'linear-gradient(325deg, #ffffff 0%, #ffffff 100%)'
        this.swalConfig.background = '#FFFFFF)'
        this.swalConfig.textColor = 'black'
      }
    },
    customizeSweetAlert2() {
      this.setSwalColor()
      return this.swalConfig
    },
    getMBfromBytes(bytes) {
      if (bytes === 0) return 0
      const sizeMB = ((bytes / (1024 * 1024)).toFixed(5))
      return sizeMB
    },

    formatDateTime(value, fmt, fmJava) {
      if (value == null || value === '') return '-'
      const fecha = (typeof fmt === 'undefined') ? 'D-MM-Y' : fmt
      const fechaJava = (typeof fmJava === 'undefined') ? 'YYYY-MM-DDTHH:mm:ssZZ' : fmJava
      return moment(value, fechaJava).format(fecha)
    },
    formatDateToJavaT(value, fmt, fmJava) {
      if (value == null || value === '') return null
      const fecha = (typeof fmt === 'undefined') ? 'DD-MM-YYYY' : fmt
      const fechaJava = (typeof fmJava === 'undefined') ? 'YYYY-MM-DD[T]HH:mm:ssZZ' : fmJava
      return moment(value, fecha).format(fechaJava)
    },
    formatDateTable(value, fmt) {
      if (value == null) return ''
      const fecha = (typeof fmt === 'undefined') ? 'D MMM YYYY' : fmt
      return moment(value, 'YYYY-MM-DD').format(fecha)
    },
    formatDateForList(value) {
      if (!value) return ''
      return moment(value, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY')
    },
    isMobileCheck() {
      const { userAgent } = navigator
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        return true
      }
      return false
    },
    getToken() {
      if (window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') && window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') !== '') {
        return JSON.parse(window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN'))
      }
      return null
    },
    isTokenExists() {
      if (window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') && window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') !== '') {
        return true
      }
      return false
    },
    /*
    getCurrentUser() {
      if (window.localStorage.getItem('ls.CURRENT-USER') && window.localStorage.getItem('ls.CURRENT-USER') !== '') {
        return JSON.parse(window.localStorage.getItem('ls.CURRENT-USER'))
      }
      return null
    },
    */
    parseJwt(token) {
      if (token == null) {
        return null
      }
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join(''))

      return JSON.parse(jsonPayload)
    },
  },
}
