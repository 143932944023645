/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllRoles,
} from '@/utils/paths'

const pathGetAllRoles = getAllRoles

export function rolResource() {
  function getAllRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllRoles,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllRoles,
  }
}
