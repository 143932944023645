<template>
  <b-overlay
    id="overlayResetPassword"
    :show="show"
    :variant="overlayVariant"
    opacity="0.8"
    class="custom-overlay"
  >
    <template #overlay>
      <slot name="overlay">
        <h3 class="d-flex flex-column align-items-center text-center">
          <span class="m-1">{{ message }}</span>
          <font-awesome-icon
            :icon="'fas fa-circle-notch'"
            spin
          />
        </h3>
      </slot>
    </template>
    <slot />
  </b-overlay>
</template>

<script>
export default {
  name: 'OverlayDefault',
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: 'Por favor, aguarde',
    },
  },
  data() {
    return {
      overlayVariant: 'light',
    }
  },
  created() {
    this.updateVariant(window.localStorage.getItem('vuexy-skin'))
    window.addEventListener('vuexy-skin-localstorage-changed', this.updateVariantStorageChanged, true)
  },
  beforeDestroy() {
    window.removeEventListener('vuexy-skin-localstorage-changed', this.updateVariant, true)
  },
  methods: {
    updateVariantStorageChanged(event) {
      const skin = event.detail.newValue
      this.updateVariant(skin)
    },
    updateVariant(skin) {
      if (skin === 'dark') {
        this.overlayVariant = 'dark'
      } else {
        this.overlayVariant = 'light'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
