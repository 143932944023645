export const ataccDomainLogin = process.env.VUE_APP_ATACC_LOGIN_BACK_URL
export const ataccDomainResetPassword = process.env.VUE_APP_ATACC_RESET_PASSWORD_BACK_URL
export const ataccDomainItemMenu = process.env.VUE_APP_ATACC_ITEM_MENU_BACK_URL
export const ataccDomainRegister = process.env.VUE_APP_ATACC_REGISTER_BACK_URL
export const ataccDomainCredencial = process.env.VUE_APP_ATACC_CREDENCIAL_BACK_URL
export const ataccDomainAbm = process.env.VUE_APP_ATACC_ABM_BACK_URL
export const ataccDomainSolicitudes = process.env.VUE_APP_ATACC_SOLICITUDES_BACK_URL
export const ataccEmpleadores = process.env.VUE_APP_ATACC_EMPLEADORES_BACK_URL
export const ataccAfiliados = process.env.VUE_APP_ATACC_AFILIADOS_BACK_URL
// Login
export const postlogin = `${ataccDomainLogin}login`
// login - reset password
export const postEmailResetPassword = `${ataccDomainResetPassword}email/reset-password`
export const postRegister = `${ataccDomainRegister}register`
export const postRenewToken = `${ataccDomainLogin}login/renew-token`

// ABM Usuario
export const getByIdUsuario = `${ataccDomainAbm}usuario/`
export const postByUsuarioByFilter = `${ataccDomainAbm}usuario/filter`
export const getByUsuarioBySpecs = `${ataccDomainAbm}usuario/specs`
export const putActivarUsuario = `${ataccDomainAbm}usuario/activar/`
export const putDesactivarUsuario = `${ataccDomainAbm}usuario/desactivar/`
export const postSaveUsuario = `${ataccDomainAbm}usuario/`
export const putUpdateUsuario = `${ataccDomainAbm}usuario/`

// ABM Stock
export const getStockBySpecs = `${ataccDomainAbm}stock/producto/specs`
// export const putActivarUsuario = `${ataccDomainAbm}usuario/activar/`
// export const putDesactivarUsuario = `${ataccDomainAbm}usuario/desactivar/`
export const postSaveStock = `${ataccDomainAbm}stock/`
export const putUpdateStok = `${ataccDomainAbm}stock/`

// Roles
export const getAllRoles = `${ataccDomainAbm}rol/`

// EstadosUsuario
export const getAllUsuarioEstado = `${ataccDomainAbm}usuarioestado/`

// Delegacion
export const getAllDelegacion = `${ataccDomainAbm}delegacion/`
export const getByIsActivoDelegacion = `${ataccDomainAbm}delegacion/findAllDelegacion`
export const postSaveDelegacion = `${ataccDomainAbm}delegacion/`
export const putUpdateDelegacion = `${ataccDomainAbm}delegacion/`

// Reportes

export const getReporteExcel = `${ataccDomainAbm}stock/reporte`
export const postReporteFechasStockExcel = `${ataccDomainAbm}stock/reporte/movimientos`

// ABM Configuración Beneficios
export const getBeneficioById = `${ataccDomainAbm}beneficio/`
export const postGetBeneficioFilter = `${ataccDomainAbm}beneficio/filter`
export const postSaveBeneficio = `${ataccDomainAbm}beneficio/`
export const putUpdateBeneficio = `${ataccDomainAbm}beneficio/`
export const getAllBeneficioTipo = `${ataccDomainAbm}beneficiotipo`

// Producto
export const postGetAllNotInThisList = `${ataccDomainAbm}producto/notinthislistids`

// Categoria solicitante
export const getAllCategoriaSolicitante = `${ataccDomainAbm}catsolic`

// Solicitud beneficios
export const getBeneficios = `${ataccDomainSolicitudes}solicitudes/beneficios/`
export const getBeneficioDetalle = `${ataccDomainSolicitudes}solicitudes/beneficioDetalle/`
export const saveSolicitudes = `${ataccDomainSolicitudes}solicitudes/`
export const saveSolicitud = `${ataccDomainSolicitudes}solicitudes/single`
export const getSolicitudesByBeneficioId = `${ataccDomainSolicitudes}solicitudes/`
export const postSolicitudesFilter = `${ataccDomainSolicitudes}solicitudes/filter/`
export const getAfiliado = `${ataccDomainSolicitudes}afiliado/`
export const getCatSolicitante = `${ataccDomainSolicitudes}catsolic/`
export const postUploadFileAttachment = `${ataccDomainSolicitudes}solicitudes/uploadfile`
export const postUploadFilesAttachment = `${ataccDomainSolicitudes}solicitudes/uploadfiles`
export const getFindInfoAdjuntos = `${ataccDomainSolicitudes}solicitudes/infoadjuntos/`
export const getAdjunto = `${ataccDomainSolicitudes}solicitudes/adjunto/`
export const getSolicitudEstado = `${ataccDomainSolicitudes}solicitudestado`
export const getBeneficiosSelect = `${ataccDomainSolicitudes}solicitudes/beneficio/basiclist`
export const getListaSelectBeneficios = `${ataccDomainSolicitudes}solicitudes/beneficios/listselect`
export const getListaSelectBeneficiosFromSolicitudes = `${ataccDomainSolicitudes}solicitudes/beneficios/listselectafi/`
export const postSaveSolicitudQuincho = `${ataccDomainSolicitudes}solicitudes/quincho`
export const getSolicitudesPreviasQuincho = `${ataccDomainSolicitudes}solicitudes/prevquincho/`
export const postVerificarYCompletarSolicitud = `${ataccDomainSolicitudes}solicitudes/verificarycompletar`

// Consulta Solicitud
export const postConsultaSolicitudFilter = `${ataccDomainSolicitudes}consultasolicitud/filter`
export const putSolicitudEstado = `${ataccDomainSolicitudes}consultasolicitud/`
export const postComentario = `${ataccDomainSolicitudes}consultasolicitud/`
export const postEntregaSolicitud = `${ataccDomainSolicitudes}consultasolicitud/entrega/`
export const postSaveEstadoUpdateComentario = `${ataccDomainSolicitudes}consultasolicitud/`
export const getReporteComprobanteSolicitud = `${ataccDomainSolicitudes}reporte/`
export const getExcelConsultaSolocitudes = `${ataccDomainSolicitudes}reporte/`
export const postNotificarRechazo = `${ataccDomainSolicitudes}email/`

// Empleadores
export const getEmpleadorById = `${ataccEmpleadores}empleadores/`
export const postEmpleadorByFilter = `${ataccEmpleadores}empleadores/filter`
export const getEmpleadorBySpecs = `${ataccEmpleadores}empleadores/specs`
export const putActivarEmpleador = `${ataccDomainAbm}empleadores/activar/`
export const putDesactivarEmpleador = `${ataccDomainAbm}empleadores/desactivar/`
export const postEmpleadorSave = `${ataccEmpleadores}empleadores/datosGenerales/`
export const putEmpleadorUpdate = `${ataccEmpleadores}empleadores/datosContacto/`
export const getVerificarCuit = `${ataccEmpleadores}empleadores/cuit/`
export const updateDatosGeneralesEmpleador = `${ataccEmpleadores}empleadores/datosGenerales/`

// Empleadores Estado
export const getAllEmpleadorEstado = `${ataccEmpleadores}empleadorestado/`

// Empleadores Logo
export const getAllEmpleadorLogo = `${ataccEmpleadores}empleadorLogo/`
export const getByIdEmpleadorLogo = `${ataccEmpleadores}empleadorLogo/`
export const postLogoSave = `${ataccEmpleadores}empleadorLogo/`

// Empleadores Categoria
export const getAllEmpleadorCategoria = `${ataccEmpleadores}empleadorCategoria/`
export const getByIsActivoEmpleadorCategoria = `${ataccEmpleadores}empleadorCategoria/findAllEmpleadorCategoria`
export const postEmpleadorCategoria = `${ataccEmpleadores}empleadorCategoria/`
export const putEmpleadorCategoria = `${ataccEmpleadores}empleadorCategoria/`

// Provincia
export const getAllProvincia = `${ataccEmpleadores}provincia/`
export const getProvinciasForLocalidadId = `${ataccEmpleadores}provincia/localidadIDs/`

// Localidad
export const getAllLocalidad = `${ataccEmpleadores}localidad/`
export const getByCodigoLocalidad = `${ataccEmpleadores}localidad/`
export const getLocalidadId = `${ataccEmpleadores}localidad/provincia/`

// Domicilio Laboral
export const findAll = `${ataccEmpleadores}domicilioLaboral/`
export const getByIdDomicilioLaboral = `${ataccEmpleadores}domicilioLaboral/`
export const postDomicilioLaboralSave = `${ataccEmpleadores}domicilioLaboral/`
export const postDomicilioLaboralSaveAll = `${ataccEmpleadores}domicilioLaboral/saveAll/`
export const getByEmpleadorId = `${ataccEmpleadores}domicilioLaboral/empleador/`
export const getByProvinciaId = `${ataccEmpleadores}domicilioLaboral/provincia/`
export const updateDomicilioLaboral = `${ataccEmpleadores}domicilioLaboral/`
export const postByEmpleadorIdFilter = `${ataccEmpleadores}domicilioLaboral/filter/`
export const putDomicilioLaboralUpdateAll = `${ataccEmpleadores}domicilioLaboral/updateAll/`

// Domicilio laboral estado
export const getAllDomicilioLaboralestado = `${ataccEmpleadores}domicilioLaboralEstado/`

// Domicilio laboral Tipo
export const getAllDomicilioLaboralTipo = `${ataccEmpleadores}domicilioLaboralTipo/`

// Empleador documento categoria
export const getByIdDocumentoCategoria = `${ataccEmpleadores}empleadorDocumentoCategoria/`
export const updateDocumentoCategoria = `${ataccEmpleadores}empleadorDocumentoCategoria/`
export const getByEmpleadorIdDocCat = `${ataccEmpleadores}empleadorDocumentoCategoria/empleador/`
export const postDocumentoCategoriaSaveAll = `${ataccEmpleadores}empleadorDocumentoCategoria/saveAll/`
export const putDocumentoCategoriaUpdateAll = `${ataccEmpleadores}empleadorDocumentoCategoria/updateAll/`
export const findAllDocCategorias = `${ataccEmpleadores}empleadorDocumentoCategoria/getAll/`
export const getByIsActivoCategoriaDocumentoEmpleador = `${ataccEmpleadores}empleadorDocumentoCategoria/findAllCategoriaDocumentacionEmpleador`
export const postSaveCategoriaDocumentoEmpleador = `${ataccEmpleadores}empleadorDocumentoCategoria/`
export const putUpdateCategoriaDocumentoEmpleador = `${ataccEmpleadores}empleadorDocumentoCategoria/categoria/`

// Empleador documento
export const getByIdDocumento = `${ataccEmpleadores}empleadorDocumento/`
export const updateDocumento = `${ataccEmpleadores}empleadorDocumento/`
export const getByEmpleadorIdDoc = `${ataccEmpleadores}empleadorDocumento/empleador/`
export const postDocumentoSaveAll = `${ataccEmpleadores}empleadorDocumento/saveAll/`
export const postDocumentoSave = `${ataccEmpleadores}empleadorDocumento/`
export const putDocumentoUpdateAll = `${ataccEmpleadores}empleadorDocumento/updateAll/`
export const postUploadFile = `${ataccEmpleadores}empleadorDocumento/uploadfile/`
export const getEmpleadorDocumentoFile = `${ataccEmpleadores}empleadorDocumento/file/`
export const deleteDocumentoEmpleador = `${ataccEmpleadores}empleadorDocumento/documento/`

// Email
export const getEmailCargaFinalizada = `${ataccEmpleadores}emailEmpleador/cargaFinalizada/`

// pruebas wizard
export const getWizardAll = `${ataccEmpleadores}wizard/`

// Afiliados
export const getAfiliadoById = `${ataccAfiliados}afiliado/`
export const postAfiliadoByFilterSpecs = `${ataccAfiliados}afiliado/filter`
export const putAfiliadoDatosContacto = `${ataccAfiliados}afiliado/datosContacto/`

// afiliación estado
export const getAfiliacionEstado = `${ataccAfiliados}afiliacionEstado/`
export const getByIsActivoAfiliacionEstado = `${ataccAfiliados}afiliacionEstado/findAllAfiliacionEstado`
export const postSaveAfiliacionEstado = `${ataccAfiliados}afiliacionEstado/`
export const putUpdateAfiliacionEstado = `${ataccAfiliados}afiliacionEstado/`
export const getAfiliadoEmpleadorAuditoria = `${ataccAfiliados}afiliadoEmpleadorAuditoria/`
export const getAfiliadoEmpleador = `${ataccAfiliados}afiliadoEmpleador/`
export const getByAfiliadoId = `${ataccAfiliados}afiliadoEmpleador/`
export const getRelacionFamiliar = `${ataccAfiliados}relacionFamiliar/`
export const getByIsActivoRelacionFamiliar = `${ataccAfiliados}relacionFamiliar/findAllRelacionFamiliar`
export const postSaveRelacionFamiliar = `${ataccAfiliados}relacionFamiliar/`
export const putUpdateRelacionFamiliar = `${ataccAfiliados}relacionFamiliar/`
export const getMotivoBaja = `${ataccAfiliados}motivoBaja/`
export const getByIsActivoMotivoBaja = `${ataccAfiliados}motivoBaja/findAllMotivoBaja`
export const postSaveMotivoBajaAfiliado = `${ataccAfiliados}motivoBaja/`
export const putUpdateMotivoBajaAfiliado = `${ataccAfiliados}motivoBaja/`
export const getEstadoLaboral = `${ataccAfiliados}estadoLaboral/`
export const getByConvenioIdEstadoLaboral = `${ataccAfiliados}estadoLaboral/`
export const postSaveEstadoLaboral = `${ataccAfiliados}estadoLaboral/`
export const putUpdateEstadoLaboral = `${ataccAfiliados}estadoLaboral/`
export const getCategoriaLaboral = `${ataccAfiliados}categoriaLaboral/`
export const getByConvenioIdCategoriaLaboral = `${ataccAfiliados}categoriaLaboral/`
export const postSaveCategoriaLaboral = `${ataccAfiliados}categoriaLaboral/`
export const putUpdateCategoriaLaboral = `${ataccAfiliados}categoriaLaboral/`
export const getConvenioTrabajo = `${ataccAfiliados}convenioTrabajo/`
export const getByIsActivoConvenioTrabajo = `${ataccAfiliados}convenioTrabajo/findAllConvenioTrabajo`
export const postConvenioTrabajo = `${ataccAfiliados}convenioTrabajo/`
export const putConvenioTrabajo = `${ataccAfiliados}convenioTrabajo/`
export const getAfiliadoLogo = `${ataccAfiliados}afiliadoLogo/`
export const getCategoriaSindical = `${ataccAfiliados}categoriaSindical/`
export const getByIsActivoCategoriaSindical = `${ataccAfiliados}categoriaSindical/findAllCategoriaSindical`
export const postSaveCategoriaSindical = `${ataccAfiliados}categoriaSindical/`
export const putUpdateCategoriaSindical = `${ataccAfiliados}categoriaSindical/`
export const getEstadoCivil = `${ataccAfiliados}estadoCivil/`
export const getByIsActivoEstadoCivil = `${ataccAfiliados}estadoCivil/findAllEstadoCivil`
export const postSaveEstadoCivil = `${ataccAfiliados}estadoCivil/`
export const putUpdateEstadoCivil = `${ataccAfiliados}estadoCivil/`
export const getAllGenero = `${ataccAfiliados}genero/`
export const getByIsActivoGenero = `${ataccAfiliados}genero/findAllGenero`
export const postSaveGenero = `${ataccAfiliados}genero/`
export const putUpdateGenero = `${ataccAfiliados}genero/`
export const getDocumentoTipo = `${ataccAfiliados}documentoTipo/`
export const getEmpleadorByRazonSocial = `${ataccAfiliados}empleadores/razonSocial/`
export const getEmpleadorByCuit = `${ataccAfiliados}empleadores/cuit/`
export const getDomicilioLaboralByEmpleadorId = `${ataccAfiliados}domicilioLaboral/`
export const getDomicilioLaboralById = `${ataccAfiliados}domicilioLaboral/dom/`
export const getVerificarCuilAfiliado = `${ataccAfiliados}afiliado/verificarCuil/`
export const postSaveDatosPersonales = `${ataccAfiliados}afiliado/datosPersonales/`
export const putUpdateDatosPersonales = `${ataccAfiliados}afiliado/datosPersonales/`
export const postSaveDatosLaborales = `${ataccAfiliados}afiliado/`
export const putUpdateDatosLaborales = `${ataccAfiliados}afiliado/`
export const getDocumentosPorCategoriaSindical = `${ataccAfiliados}afiliadoCatSindicalDocumento/`
export const getReglasPorDocumentoSindical = `${ataccAfiliados}afiliadoCatSindicalDocumento/reglas/`
export const postReglaSindical = `${ataccAfiliados}afiliadoCatSindicalDocumento/`
export const putReglaSindical = `${ataccAfiliados}afiliadoCatSindicalDocumento/regla/`
export const deleteReglaSindical = `${ataccAfiliados}afiliadoCatSindicalDocumento/`
export const postUploadAfiliadoDocumento = `${ataccAfiliados}afiliado/uploadfile/`
export const getListaAfiliadoDocumento = `${ataccAfiliados}afiliado/infoadjuntos/`
export const getAdjuntoAfiliadoDocumento = `${ataccAfiliados}afiliado/documento/adjunto/`
export const postFinalizarCargaAfiliado = `${ataccAfiliados}afiliado/finalizar/`
export const postAllDatosLaborales = `${ataccAfiliados}afiliado/datosLaborales/saveAll`
export const putAllDatosLaborales = `${ataccAfiliados}afiliado/datosLaborales/updateAll`
export const deleteAfiliadoDocumento = `${ataccAfiliados}afiliado/documento/`

export const getExcelConsultaAfiliado = `${ataccAfiliados}afiliadoReporte/`

export const findAllDocCategoriasAfiliado = `${ataccAfiliados}afiliadoDocumentoCategoria/getAll/`
export const getByIsActivoCategoriaDocumentoAfiliado = `${ataccAfiliados}afiliadoDocumentoCategoria/findAllCategoriaDocumentacionAfiliado`
export const postSaveCategoriaDocumentoAfiliado = `${ataccAfiliados}afiliadoDocumentoCategoria/`
export const putUpdateCategoriaDocumentoAfiliado = `${ataccAfiliados}afiliadoDocumentoCategoria/categoria/`
// relacion familiar
export const getAllRelacionFamiliar = `${ataccAfiliados}relacionFamiliar/`

// grupo familiar
export const postAllAdherente = `${ataccAfiliados}afiliadoAdherente/saveAll/`
export const putAllAdherente = `${ataccAfiliados}afiliadoAdherente/updateAll/`
export const postAdherente = `${ataccAfiliados}afiliadoAdherente/save/`
export const putAdherente = `${ataccAfiliados}afiliadoAdherente/update/`
export const getByAdherente = `${ataccAfiliados}afiliadoAdherente/searchAdherente/`
export const deleteAdherente = `${ataccAfiliados}afiliadoAdherente/`

export const getByDocumentosPorReglas = `${ataccAfiliados}afiliadoAdherenteDocumentoReglas/reglas/`
export const getByReglasPorCategoria = `${ataccAfiliados}afiliadoAdherenteDocumentoReglas/categoria/`
export const postReglaAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoReglas/`
export const putReglaAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoReglas/regla/`
export const deleteReglaAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoReglas/`
export const getByDocumentosPorCategoria = `${ataccAfiliados}afiliadoAdherenteDocumentoCategoria/categorias/`
export const getByIsActivoCategoriaDocumentoAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoCategoria/findAllCategoriaDocumentacionAdherente`
export const postSaveCategoriaDocumentoAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoCategoria/`
export const putUpdateCategoriaDocumentoAdherente = `${ataccAfiliados}afiliadoAdherenteDocumentoCategoria/categoria/`

// afiliadoAdjuntoAdherente
export const postUploadAfiliadoAdherenteDocumento = `${ataccAfiliados}afiliadoAdjuntoAdherente/uploadfile/`
export const getListaAfiliadoAdherenteDocumento = `${ataccAfiliados}afiliadoAdjuntoAdherente/infoadjuntos/`
export const getAdjuntoAfiliadoAdherenteDocumento = `${ataccAfiliados}afiliadoAdjuntoAdherente/documento/adjunto/`
export const deleteAfiliadoAdherenteDocumento = `${ataccAfiliados}afiliadoAdjuntoAdherente/documento/`

export const getAllParametrosDdjj = `${ataccAfiliados}parametrosDDJJ/`
export const getByFilterConvenioTrabajo = `${ataccAfiliados}parametrosDDJJ/findByConvenioTrabajo/`
export const getConveniosTrabajo = `${ataccAfiliados}parametrosDDJJ/conveniosTrabajo/`
export const putParametroDdjj = `${ataccAfiliados}parametrosDDJJ/`
export const getByTipoPresentacion = `${ataccAfiliados}parametrosDDJJ/tipoPresentacion/`
export const getByMontoBaseParaProceso = `${ataccAfiliados}parametrosDDJJ/montoBaseParaProceso/`
export const getByPlazoAlmacenamientoArchivos = `${ataccAfiliados}parametrosDDJJ/plazoAlmacenamientoArchivos/`
export const getItemsActualizados = `${ataccAfiliados}parametrosDDJJ/itemsActualizados`
export const getByTipoCuentas = `${ataccAfiliados}parametrosDDJJ/tipoCuentas/`

export const getCuentaBancaria = `${ataccEmpleadores}cuentaBancaria/`
export const getByIsActivoCuentaBancaria = `${ataccEmpleadores}cuentaBancaria/findAllCuentaBancaria`
export const postCuentaBancaria = `${ataccEmpleadores}cuentaBancaria/`
export const putCuentaBancaria = `${ataccEmpleadores}cuentaBancaria/`
export const getFilterByNumeroCuenta = `${ataccEmpleadores}cuentaBancaria/nroCuenta/`

export const getEntidadBancaria = `${ataccEmpleadores}entidadBancaria/`
export const getByIsActivoEntidadBancaria = `${ataccEmpleadores}entidadBancaria/findAllEntidadBancaria`
export const postEntidadBancaria = `${ataccEmpleadores}entidadBancaria/`
export const putEntidadBancaria = `${ataccEmpleadores}entidadBancaria/`

export const getCodigoRetencion = `${ataccEmpleadores}codigoRetencion/`
export const getByIsActivoCodigoRetencion = `${ataccEmpleadores}codigoRetencion/findAllCodigoRetencion`
export const postCodigoRetencion = `${ataccEmpleadores}codigoRetencion/`
export const putCodigoRetencion = `${ataccEmpleadores}codigoRetencion/`

export const getReglasAplicacionRetenciones = `${ataccEmpleadores}reglasAplicacionRetenciones/`
export const getByIsActivoReglasAplicacionRetenciones = `${ataccEmpleadores}reglasAplicacionRetenciones/findAllReglasAplicacionRetenciones`
export const postReglasAplicacionRetenciones = `${ataccEmpleadores}reglasAplicacionRetenciones/`
export const putReglasAplicacionRetenciones = `${ataccEmpleadores}reglasAplicacionRetenciones/`
