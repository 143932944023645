export default [
  {
    path: '/consultabeneficiolista',
    name: 'consulta',
    component: () => import('@/views/beneficios/consulta/ConsultaBeneficioLista.vue'),
    meta: {
      modulo: 'CONSULTA_BENEFICIO',
      permiso: 'VER',
    },
  },
]
