export default [
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('@/views/usuario/UsuarioLista.vue'),
    meta: {
      nameParent: 'usuarios',
      modulo: 'ABM_USUARIO',
      permiso: 'VER',
    },
  },
  {
    path: '/usuarios/view/:idUsuario',
    name: 'usuarios-view',
    component: () => import('@/views/usuario/Usuario.vue'),
    props: route => ({ idUsuario: route.params.idUsuario, openMode: 'view' }),
    meta: {
      // pageTitle: 'Usuarios',
      nameParent: 'usuarios',
    },
  },
  {
    path: '/usuarios/edit/:idUsuario',
    name: 'usuarios-edit',
    component: () => import('@/views/usuario/Usuario.vue'),
    props: route => ({ idUsuario: route.params.idUsuario, openMode: 'edit' }),
    meta: {
      // pageTitle: 'Usuarios',
      nameParent: 'usuarios',
    },
  },
  {
    path: '/usuarios/new',
    name: 'usuarios-new',
    component: () => import('@/views/usuario/Usuario.vue'),
    props: { openMode: 'new' },
    meta: {
      nameParent: 'usuarios',
    },
  },
]
