import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  handleRouterBeforeEach,
} from 'trx-vue-common/src/security/permisos'
import store from '@/store'
import usuario from './usuario'
import stock from './stock'
import configuracionBeneficios from './configuracionBeneficios'
import solicitudBeneficios from './solicitudBeneficios'
import consultaBeneficio from './consultaBeneficio'
import empleador from './empleador'
import afiliado from './afiliado'
import parametros from './parametros'
import parametrosDDJJ from './parametrosDDJJ'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/inicio',
    },
    ...usuario,
    ...stock,
    ...configuracionBeneficios,
    ...solicitudBeneficios,
    ...consultaBeneficio,
    ...empleador,
    ...afiliado,
    ...parametros,
    ...parametrosDDJJ,
    {
      path: '/inicio',
      name: 'inicio',
      component: () => import('@/views/inicio/Inicio.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/credencial',
      name: 'credencial',
      component: () => import('@/views/credencial/Credencial.vue'),
      meta: {
        modulo: 'CREDENCIAL',
        permiso: 'VER',
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/reset-password/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
    },
    {
      path: '/sinPermisos',
      name: 'sin-permisos',
      component: () => import('@/views/error/SinPermisos.vue'),
    },
    {
      path: '/wip',
      name: 'wip',
      component: () => import('@/views/en-construccion/PaginaEnConstruccion.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  handleRouterBeforeEach(to, from, next, store, router)
})

export default router
