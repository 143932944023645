/* eslint-disable no-param-reassign */
import Vue from 'vue'

/*
* Agrega el valor 'application/json' al header indicado, si es que no posee un valor definido por el usuario.
*/
const configJsonHeaders = (config, header) => {
  if (!config.headers[header]) {
    config.headers[header] = 'application/json'
  }
}

const toast = (message, type) => {
  Vue.prototype.$toast(message, { type })
}

const toastXError = toastText => {
  toast(toastText, 'error')
}

function getToken() {
  if (window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') && window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN') !== '') {
    return JSON.parse(window.localStorage.getItem('ls.X-AUTHENTICATION-TOKEN'))
  }
  return null
}

/*
* Si es necesario que el request sea enviado sin token, se debe de indicar ej:
* this.$http.post('url', params, {sinToken: true}).then(...)
*/
export function requestInterceptor(config) {
  configJsonHeaders(config, 'Accept')
  configJsonHeaders(config, 'Content-Type')
  if (config.sinToken) {
    return config
  }
  if (getToken() !== null) {
    config.headers['X-AUTHENTICATION-TOKEN'] = getToken()
  }
  return config
}

export function requestErrorInterceptor(error) {
  return Promise.reject(error)
}

export function responseInterceptor(response) {
  if (response.headers['x-alert']) {
    toast(response.headers['x-alert'], 'warning')
  }
  if (response.headers['x-danger']) {
    toast(response.headers['x-danger'], 'danger')
  }
  if (response.headers['x-info']) {
    toast(response.headers['x-info'], 'info')
  }
  if (response.headers['x-success']) {
    toast(response.headers['x-success'], 'success')
  }
  return response
}

export function responseErrorInterceptor(error, router) {
  if (error.message.includes('Network')) {
    toastXError('Error de comunicación')
    return Promise.reject(error)
  }
  if (!error.response) {
    return Promise.reject(error)
  }
  if (error.response.status === 400) {
    toastXError('error.response.data.error')
    return Promise.reject(error)
  }
  if (error.response.status === 401) {
    toastXError(error.response.data.error)
    router.push('/login')
    return Promise.reject(error)
  }
  if (error.response.status === 403) {
    toastXError('No posee permisos para ver estos recursos.')
    router.push('/sinPermisos')
    return Promise.reject(error)
  }

  if (error.response.status === 404) {
    toastXError('No se pudo contactar con el servidor, intente nuevamente en unos minutos.')
    return Promise.reject(error)
  }

  if (error.response.data.listaMensaje
    && error.response.data.listaMensaje.listaErrores
    && error.response.data.listaMensaje.listaErrores.length > 0) {
    error.response.data.listaMensaje.listaErrores.forEach(e => {
      toast(e.mensajeError, e.mensajeTipo)
    })
    return Promise.reject(error)
  }

  if (error.response.headers['x-error']) {
    let toastText
    if (error.response.data instanceof Blob) {
      error.response.data.text().then(text => {
        const textObject = JSON.parse(text)
        toastText = textObject.error ? textObject.error : error.response.headers['x-error']
        toastXError(toastText)
      })
    } else {
      toastText = error.response.data.error ? error.response.data.error : error.response.headers['x-error']
      toastXError(toastText)
    }
    return Promise.reject(error)
  }
  if (error.response.headers['x-danger']) {
    const toastText = error.response.data.error ? error.response.data.error : error.response.headers['x-danger']
    toastXError(toastText)
    return Promise.reject(error)
  }
  if (error.response.headers['x-alert']) {
    const toastText = error.response.data.error ? error.response.data.error : error.response.headers['x-alert']
    toast(toastText, 'warning')
    return Promise.reject(error)
  }
  if (error.response.headers['x-info']) {
    const toastText = error.response.data.error ? error.response.data.error : error.response.headers['x-info']
    toast(toastText, 'info')
    return Promise.reject(error)
  }
  if (error.response.headers['x-success']) {
    const toastText = error.response.data.error ? error.response.data.error : error.response.headers['x-success']
    toast(toastText, 'success')
    return Promise.reject(error)
  }
  if (error.response.status === 500) {
    // debe ir al ultimo ya que la bussines expetion da un 500 y necesito mostrar el mensaje de error
    toastXError('Se produjo un error. Contacte con el administrador.')
    return Promise.reject(error)
  }
  toastXError('Error de comunicación')
  return Promise.reject(error)
}
