export default [
  {
    path: '/parametrosDDJJ',
    name: 'parametrosDDJJ',
    component: () => import('@/views/parametros/AbmParametrosDDJJ.vue'),
    meta: {
      nameParent: 'parametrosDDJJ',
      modulo: 'ABM_PARAMETROS_DDJJ',
      permiso: 'VER',
    },
  },
]
