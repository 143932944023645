/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllCategoriaSolicitante,
} from '@/utils/paths'

const pathGetAllCategoriaSolicitante = getAllCategoriaSolicitante

export function categoriaSolicitanteResource() {
  function findAllCategoriaSolicitante() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllCategoriaSolicitante,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    findAllCategoriaSolicitante,
  }
}
