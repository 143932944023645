/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllDelegacion,
  getByIsActivoDelegacion,
  postSaveDelegacion,
  putUpdateDelegacion,
} from '@/utils/paths'

const pathGetAllDelegacion = getAllDelegacion
const pathGetByIsActivoDelegacion = getByIsActivoDelegacion
const pathPostSaveDelegacion = postSaveDelegacion
const pathPutUpdateDelegacion = putUpdateDelegacion

export function delegacionResource() {
  function findAllDelegacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDelegacion,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoDelegacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoDelegacion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveDelegacion(delegacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveDelegacion, delegacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateDelegacion(id, delegacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateDelegacion + id, delegacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllDelegacion,
    findAllByIsActivoDelegacion,
    saveDelegacion,
    updateDelegacion,
  }
}
