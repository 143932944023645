/* eslint-disable no-shadow */
import {
  categoriaSolicitanteResource,
} from '@/services/categoriaSolicitanteResource'

const state = {
  categoriaSolicitante: [],
}

const mutations = {
  SET_CATEGORIA_SOLIC(state, categoriaSolicitante) {
    state.categoriaSolicitante = categoriaSolicitante
  },
}

const actions = {
  setCategoriaSolicitante: ({ commit }, categoriaSolicitante) => {
    commit('SET_CATEGORIA_SOLIC', categoriaSolicitante)
  },
  fetchCategoriaSolicitante: ({ dispatch }) => new Promise((resolve, reject) => {
    categoriaSolicitanteResource().findAllCategoriaSolicitante()
      .then(data => {
        dispatch('setCategoriaSolicitante', data)
        resolve(data)
      })
      .catch(error => {
        console.error(error)
        reject(error)
      })
      .finally(() => {
      })
  }),
}

const getters = {
  getCategoriaSolicitante(state) {
    return state.categoriaSolicitante
  },
}

export default {
  state, mutations, actions, getters,
}
